<template>
  <v-row no-gutters class="py-4">
    <v-col cols="12" sm="4">
      <h3>Account overview</h3>
    </v-col>
    <v-col cols="12" sm="8">
      <v-row class="elevation-form pa-3">
        <v-col cols="4" class="pb-0">
          <h4>Member since</h4>
          <p>{{ memberSince }}</p>
        </v-col>
        <v-col cols="8" class="pb-0">
          <h4>Current plan</h4>
          <p>{{ currentSubscription }}</p>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <p class="mb-0">
            Grow your business by upgrading now
          </p>
          <ul class="pl-4 mb-4">
            <li>Start selling online with your secure, nicely decorated store with unlimited products</li>
          </ul>
          <!-- <v-btn color="primary" @click="$router.push({ name: 'website.pricing' })">Upgrade Plan</v-btn> -->
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment';
export default {
  computed: {
    currentSubscription() {
      const { currentSubscription, trialInfo } = this.$store.state.subscription;
      if (currentSubscription != null) {
        return currentSubscription.name;
      } else return trialInfo.diff < 0 ? 'No plan' : 'Free trial';
    },
    memberSince() {
      return moment(this.$store.getters.user?.memberSince).format('MMM D, YYYY');
    },
  },
};
</script>

<style scoped></style>
